import Sentry from '../util/Sentry';

const BASE_URL = process.env.API_URL;

export async function customFetch(method, path, body) {
  const options = {
    method,
    credentials: 'include',
    ...(body && { body: JSON.stringify(body) }),
  };

  const res = await fetch(`${BASE_URL}${path}`, options);
  const json = await res.json();
  if (!res.ok) {
    if (res.status === 500) {
      Sentry.captureException(res,
        { tags: { api_method: method, api_path: path, request_body: body } });
    }
    throw json;
  }
  return json;
}

const get = async (email) => customFetch('GET', `/user/${email}`);
const update = async (email, fields) => customFetch('PUT', `/user/${email}`, fields);
const requestMatch = async (email, timesAvailable, matchesRequested, type) => customFetch('POST', `/user/${email}/addmatch`, { timesAvailable, matchesRequested, type });
const verify = async (email, token) => customFetch('POST', `/verify?email=${encodeURIComponent(email)}&token=${token}`);
const sendResetLink = async (email) => customFetch('POST', `/lostpassword/${email}`);
const changePassword = async (email, token, password) => customFetch('POST', `/changepassword?email=${encodeURIComponent(email)}&token=${token}`, { password });
const confirmMeeting = async (email, otherEmail, confirmed) => customFetch('POST', `/user/${email}/confirm`, { otherEmail, confirmed });
const nudge = async ({
  senderName, senderEmail, name, email,
}) => customFetch('POST', `/user/${senderEmail}/nudge`, { senderName, name, email });

export default {
  get,
  update,
  requestMatch,
  verify,
  sendResetLink,
  changePassword,
  confirmMeeting,
  nudge,
};
